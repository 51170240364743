#header-slider {
  width: 100%;
  position: relative;
  height: 65vh;
  border-bottom: .2rem solid #fff;
  margin-top: 50px;

  .headerSlider_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    color: white;
    font-size: 28px; }

  .headerSlider_left {
    left: 10%; }

  .headerSlider_right {
    right: 10%; }

  .slide {
    position: relative;
    width: 100%;
    height: 65vh;
    background: black;
    background-repeat: no-repeat;
    background-size: cover;
    backround-position: center center;
    box-shadow: 0 0 200px rgba(0,0,0,0.9) inset;

    h1 {
      position: absolute;
      top: 7.5%;
      left: 10%;
      font-family: 'DINProBold';
      color: white;
      font-size: 28px;
      letter-spacing: .025em;
      text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.4); }

    .headline__center {
      top: 50%;
      left: 50%;
      font-size: 38px;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      text-align: center; } } }

@media screen and (max-width: 685px) {
  #header-slider {
    .slide {
      .headline__center {
        font-size: 20px; } } } }

@media screen and (max-width: 600px) {
  #header-slider {
    .slide {
      h1 {
        font-size: 18px; } } } }
