* {
  box-sizing: border-box; }

html, body {
  padding: 0;
  margin: 0; }

body {
  font-family: 'DINProRegular'; }

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }
