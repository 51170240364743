.products-container {
  position: relative;
  padding: 7.5% 5% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .product {
    position: relative;
    width: 33%;
    height: 50vh;
    max-width: 33%;
    text-align: center;
    margin-bottom: 7.5%;

    .product-inner {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%); }

    h1 {
      margin-top: 0;
      font-family: 'DINProBold';
      font-size: 28px; }

    h2 {
      color: #b19f9f; } } }

@media screen and (max-width: 900px) {
  .products-container {
    .product {
      width: 50%;
      max-width: 50%; } } }

@media screen and (max-width: 600px) {
  .products-container {
    .product {
      width: 100%;
      max-width: 100%;

      h1 {
        font-size: 24px; }

      h2 {
        font-size: 12px; }

      img {
        width: 45%; } } } }
