@font-face {
  font-family: 'DINProRegular';
  src: url('../fonts/DINProRegular.otf');
  font-weight: normal; }

@font-face {
  font-family: 'DINProBold';
  src: url('../fonts/DINProBold.otf');
  font-weight: normal; }


@font-face {
  font-family: 'DINProBlack';
  src: url('../fonts/DINProBlack.otf');
  font-weight: normal; }


//LIGATURE FONT

@font-face {
  font-family: 'LigatureSymbols';
  src: url('../fonts/LigatureSymbols-2.11.eot');
  src: url('../fonts/LigatureSymbols-2.11.eot?#iefix') format('embedded-opentype'), url('../fonts/LigatureSymbols-2.11.woff') format('woff'), url('../fonts/LigatureSymbols-2.11.ttf') format('truetype'), url('../fonts/LigatureSymbols-2.11.svg#LigatureSymbols') format('svg');
  src: url('../fonts/LigatureSymbols-2.11.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; }

.lsf,
.lsf-icon:before {
  font-family: 'LigatureSymbols';
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased; }


.lsf-icon:before {
  content: attr(title);
  font-size: 130%; }


.lsf-icon.amazon:before {
  content: '\E007'; }

.lsfSizeBig {
  font-size: 2vw; }
