p {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .025em;
  color: #292a2c;
  margin: 15px 0; }

h2 {
  font-family: 'DINProBold';
  letter-spacing: .025em;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0; }

a.link {
  letter-spacing: .025em;

  &:after {
    display: block;
    content: '';
    width: 0%;
    height: 2px;
    background: black;
    transition: all .3s; }

  &:hover {
    &:after {
      width: 100%; } } }
