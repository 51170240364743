.border-btn {
  position: relative;
  border: 2px solid #292a2c;
  background: transparent;
  transition: all .2s;
  padding: 2px 8px 3px;

  a {
    font-family: 'DINPro';
    font-size: 12px;
    letter-spacing: .025em;
    color: #292a2c; }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    z-index: -1;
    background: black;
    transition: all .3s ease-out; }

  &:hover {
    a {
      color: white !important; }

    &:after {
      width: 100%; } } }
