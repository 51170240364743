.text-container-2col {
  background: #e3d8d3;
  padding: 3em;
  border-bottom: .2rem solid #fff;

  &::after {
    content: '';
    display: block;
    clear: both; }

  .headline-container {
    float: left;
    width: 50%;

    h1 {
      font-family: 'DINProBold';
      font-size: 42px;
      text-transform: uppercase;
      letter-spacing: .025em;
      margin-top: 0; }

    h2 {
      font-family: 'DINProBold';
      font-size: 24px;
      letter-spacing: .025em;
      margin: 0; } }

  .text-container {
    float: left;
    width: 50%;

    p {
      font-size: 12px;
      line-height: 18px; } } }

@media screen and (max-width: 985px) {
  .text-container-2col {
    .headline-container {
      width: 100%;

      h1 {
        font-size: 7.4vw; }

      h2 {
        font-size: 4vw; }

      p {
        line-height: 4vw;
        font-size: 2.5vw; } }

    .text-container {
      width: 100%; } } }
