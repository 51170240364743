.top-bar {
  width: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5vh 2.5vw;
  font-family: 'DINProBold';
  font-size: 12px;
  letter-spacing: .025em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  background: white;

  .lsf-icon {
    letter-spacing: normal; }

  .menu a {
    color: black; }

  .menu .border-btn {
    padding: 5px 6px 4px;

    a {
      font-family: 'DINProBold';
      font-size: 12px;
      letter-spacing: .025em;
      text-transform: uppercase; } }

  .mobile_menu {
    display: none; }

  .menu .hamburger {
    position: relative;
    display: block;
    border: 0;
    background: transparent;
    cursor: pointer;
    outline: none;

    span {
      display: block;
      width: 15px;
      height: 2px;
      margin-bottom: 3px;
      background: black;

      &:last-child {
        margin-bottom: 0; } } }

  span {
    text-transform: none; }

  li {
    display: inline-block;
    margin-right: 1.75vw;

    &:last-child {
      margin-right: 0; } }

  .logo-dropdownMenu-search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #000;
      margin-right: 1.75vw;

      &:last-child {
        margin-right: 0; }

      &:nth-child(2) {
        color: #b4302c; }

      span {
        margin-right: 5px; } }

    img {
      width: 115px;
      height: auto;
      margin-right: 3.5vw; } } }

.mobile_menu_list {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100vh;
  z-index: 999999;

  .logo-menu {
    position: absolute;
    width: 30%;
    height: auto;
    top: 2.5%;
    left: 5%; }

  .close-menu-btn {
    position: absolute;
    top: 3%;
    right: 5%;
    font-size: 5vw; }

  ul {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

  li {
    width: 100vw;
    text-align: center;
    padding: 5%;

    a {
      display: block;
      color: black; } } }

.mobile_menu_list__show {
  visibility: visible;
  opacity: 1; }

@media screen and (max-width: 830px) {
  .top-bar {
    font-size: 10px;

    .logo-dropdownMenu-search img {
      width: 22.5vw; }

    .desktop_menu {
      display: none; }

    .mobile_menu {
      display: block; } } }
