.slogan-container {
  position: relative;
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 200px rgba(0,0,0,0.9) inset;
  border-top: .2rem solid #fff;
  border-bottom: .2rem solid #fff;

  .slogan {
    position: absolute;
    top: 40%;
    left: 10%;

    p {
      color: white;
      font-family: 'DINProBold';
      font-size: 48px;
      line-height: 62px;
      letter-spacing: .025em; } } }



@media screen and (max-width: 600px) {
  .slogan-container {
    .slogan {
      top: 60%;

      p {
        font-size: 7.4vw;
        line-height: 11vw; } } } }
