footer {
  .impressum-bar {
    border-top: .2rem solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #504946;
    padding: .5em 1em;

    .copyright {
      color: white;
      font-size: 12px; }

    .social {
      a {
        display: inline-block;
        margin: 0 5px;

        img {
          width: 30px;
          height: auto; } } }

    .impressum {
      color: white;
      font-size: 12px;

      a {
        color: white; } } } }


@media screen and (max-width: 500px) {
  footer {
    .impressum-bar {
      flex-direction: column;

      .copyright,
      .social {
        margin-bottom: 2.5%; } } } }
