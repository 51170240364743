.about-container {
  position: relative;
  width: 100%;
  background: #f2ebe9;
  border-top: .2rem solid #fff;
  border-bottom: .2rem solid #fff;
  padding: 7.5%;
  transform: translate3d(0, 0, 0);

  &::after {
    content: '';
    display: block;
    clear: both; }

  .col_33 {
    position: relative;
    float: left;
    width: 33.333%;

    .text_block {
      margin-bottom: 40px;

      &:nth-child(2) {
        margin-bottom: 0; } }

    .col2-text_block {
      padding-right: 20%;
      display: flex;
      margin-bottom: 20px;

      .text {
        p {
          margin: 0; } }

      .icon {
        padding-right: 25px; } }

    &:last-child {
      margin-bottom: 0; } } }


@media screen and (max-width: 750px) {
  .about-container {
    .col_33 {
      width: 100%;
      text-align: center;
      margin-bottom: 15%;

      .col2-text_block {
        padding-right: 0;
        flex-direction: column;

        .icon {
          padding-right: 0; } } }

    &:last-child {
      margin-bottom: 0; } } }
