.tile {
  position: relative;
  width: 50%;
  height: 40vh;
  float: left;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: .2rem solid #fff;
  filter: grayscale(.7);
  transition: all .2s ease-out;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    filter: grayscale(0);

    .border-btn {
      a {
        color: white !important; }

      &::after {
        width: 100%; } } }

  .content {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);

    .subheader {
      font-family: 'DINProBold';
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: .025em;
      color: #bf332f;
      margin: 0; }

    .header {
      font-family: 'DINProBold';
      font-size: 28px;
      letter-spacing: .025em;
      margin: 0; }

    .text {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: .025em;
      color: #292a2c;
      margin: 15px 0; } }

  .content__right {
    left: auto;
    right: 5%;
    text-align: right; } }

.tile__big {
  width: 55%; }

.tile__small {
  width: 45%; }


@media screen and (max-width: 745px) {
  .tile {
    width: 100%;
    height: 50vh;

    .content__right {
      left: 5%;
      right: auto;
      text-align: left; } } }
