.partner-container {
    width: 100%;
    background: #e3d8d3;
    border-top: .2rem solid #fff;
    border-bottom: .2rem solid #fff;
    padding: 5vh 0;

    .subheader {
      font-family: 'DINProBold';
      letter-spacing: .025em;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 0;
      margin-bottom: 1.5em; }

    .partner-slider {
      width: 100%;
      padding: 0 10vw;

      .partnerSlider_arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        font-size: 32px;
        left: 5%; }

      .partnerSlider_right {
        right: 5%;
        left: auto; }

      .page {
        position: relative;
        width: 100%;

        &::after {
          content: '';
          display: block;
          clear: both; }

        .partner {
          float: left;
          width: 16%;
          height: auto;
          padding: 1rem 1.5rem; } } } }


@media screen and (max-width: 890px) {
  .partner-container {
    .partner-slider {
      .page {
        .partner {
          width: 50%; } } } } }
