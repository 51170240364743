.dropdownMenu_list {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  visibility: hidden;
  opacity: 0;
  padding-top: 65px;

  .top-bar {
    border-bottom: 1px solid black;
    position: fixed;
    top: 0; } }

.dropdownMenu_list--show {
  visibility: visible;
  opacity: 1;
  z-index: 99999999;

  .dropdownMenu_2col_content {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    .col-50 {
      position: relative;
      width: 50%; }

    .preview {
      position: relative;
      height: 100%;
      text-align: center;

      img {
        width: 65%;
        height: auto; } }

    .list {
      .accordion {
        input[type="radio"] {
          position: absolute;
          left: -9999rem; }

        label {
          display: block;
          background: transparent;
          color: black;
          text-align: center;
          padding: .75rem;
          padding-left: .13em;
          padding-right: 0;
          padding-bottom: 0;
          cursor: pointer;
          transition: color 400ms;

          .lsf-icon {
            text-transform: none;
            letter-spacing: normal;
            position: absolute;
            right: 1%;
            color: #ccc; } }

        .baffle {
          font-family: 'DINProBold';
          font-size: 12px;
          letter-spacing: .025em;
          text-transform: uppercase;
          position: relative;
          overflow: hidden;
          border-bottom: 1px solid #ccc;
          line-height: 24px;
          padding-bottom: .75rem; }

        .baffle-inner {
          padding: 0;
          background: transparent;
          max-height: 0;
          text-align: center;
          left: 0;
          right: 0;
          transition: max-height 1000ms;
          overflow: hidden;

          ul {
            display: none;

            li:hover a {
              color: #b19f9f; } }

          a {
            color: #575757;
            transition: color .1s; } }

        .baffle-inner:first-child {
          margin-top: 0; }

        .baffle-inner:last-child {
          margin-bottom: 0; }

        input[type="radio"]:checked + .baffle .baffle-inner {
          max-height: 500px;

          ul {
            display: block; } }

        input[type="radio"]:checked + .baffle label {
          color: #bf332f;

          .lsf-icon {
            color: #bf332f;
            transform: rotate(-90deg); } } } } } }
